<template lang="pug">
  v-dialog(v-model='dialog' max-width='95%' )
    v-card(:loading="isLoading")
      //v-card-text
      //  | USER {{user}}
      //  br
      //  | EVENT{{event}}
      //  br
      //  | ASSIGNMENT {{assignment}}
      //  |BLA
      v-card-title.text-h5.text-uppercase(v-if="event && user")
        span Infos/Dokumente zum Teilnehmer
        span.text-underline.mx-2 {{user.fullname?user.fullname:user.username}}
        | für Veranstaltung am
        span.text-underline.mx-2 {{event.date|shortGermanDate}}
        v-spacer
        app-documents-create-button(:assignment="assignment" v-if="hasRole('ROLE_DOCUMENT_CREATE') ")
      v-card-text(v-if="user && event && localAssignment")
        v-card-text(v-if="hasRole('ROLE_DOCUMENT_UPDATE')" ).thin-rounded-border
          v-row
            v-col
              v-textarea(v-model="localAssignment.description" :label="$t('events.description')" :hint="$t('events.descriptionHint')" auto-grow)
            v-col
                h3.pb-3 {{$t('events.descriptionPreview')}}
                p(v-html="description")
          v-row
            v-col.text-right

              v-btn(color="green" @click="saveAssignment")
                v-icon(small).mr-2 fa-save
                | Speichern

        v-card-text(v-else-if="description" v-html="description").thin-rounded-border
        v-card-text(v-else).thin-rounded-border Keine Beschreibung/Infos vorhanden

      v-card-text(v-if="user && event")
        //app-documents-create-line(:assignment="assignment")

        app-documents-list(v-if="documents && documents.length>0" :documents="documents")
        span(v-else) Keine Dokumente verfügbar.
      v-card-actions
        v-spacer
        v-btn(@click='dialog = false').text-none
          v-icon.mr-2(small) fa-times
          | Schließen

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppDocumentsCreateLine from '@/modules/Documents/components/app-documents-create-line'
import AppDocumentsList from '@/modules/Documents/components/app-documents-list'
import AppDocumentsCreateButton from '@/modules/Documents/components/app-documents-create-button'
import { hasRole } from '@/modules/Authentication/helper/Checks'
import { cloneDeep } from 'lodash'
import { shortGermanDate } from '@/modules/Core/filter/dateFilter'

export default {
  name: 'Assignment',
  components: { AppDocumentsCreateButton, AppDocumentsList, AppDocumentsCreateLine },
  props: ['eventId', 'assignmentId'],
  metaInfo () {
    return { title: this.$t('events.description') + ' "' + (this.user ? (this.user.fullname ? this.user.fullname : this.user.username) : '') + '" an ' + (this.event ? this.event.name : 'loading') }
  },

  data () {
    return {
      localAssignment: null,
      dialog: true
    }
  },
  computed: {
    ...mapGetters({
      isLoadingUsers: 'users/isLoading',
      isLoadingEvents: 'events/isLoading',
      getAssignment: 'assignments/byId',
      getDocuments: 'documents/byAssignment',
      getEvent: 'events/byId',
      getUser: 'users/byIRI'
    }),
    isLoading () { return this.isLoadingEvents || this.isLoadingUsers },
    assignment () {
      // console.log('assignment', this.assignmentId)
      return this.getAssignment(this.assignmentId)
    },
    event () { return this.getEvent(this.eventId) },
    user () {
      // console.log(this.assignment, this.assignment?.user, this.getUser(this.assignment?.user))
      if (!this.assignment || !this.assignment.user) return null; else return this.getUser(this.assignment.user)
    },
    documents () { return this.getDocuments(this.assignment) },
    description () {
      if (!this.localAssignment || !this.localAssignment.description) return null
      return this.marked(this.localAssignment.description)
    }
  },
  mounted () {
    this.LoadDocuments({ assignment: this.assignmentId })
    this.loadAssignment(this.assignmentId)
    this.localAssignment = cloneDeep(this.assignment)
  },
  watch: {
    assignment (value) {
      this.localAssignment = cloneDeep(value)
    },
    dialog (val) {
      if (val === false) {
        this.$router.push({ name: 'EventsDetail', params: this.$route.params })
      }
    }
  },
  methods: {
    shortGermanDate,
    ...mapActions({ LoadDocuments: 'documents/getEntries', updateEntity: 'assignments/putEntry', loadAssignment: 'assignments/getEntry' }),
    hasRole,
    saveAssignment () {
      this.updateEntity(this.localAssignment).then(() => {
        this.$awn.success('Info gespeichert.')
      })
    }
  }
}
</script>

<style scoped>
.thin-rounded-border{
  border:thin solid lightgrey;
  border-radius: 5px;
  min-height: 30px;
  width: 100%;
}
</style>
