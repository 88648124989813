<template lang="pug">
.create-event
  v-container(fluid)
    v-row
      v-col
        h1 {{$route.query.copyFrom?$t('events.copy'):$t('events.create')}}

  v-container(fluid)
    v-row
      v-col
        v-card(color="transparent" flat)
          v-card-text
            //v-row
              v-col
                pre {{copyFromEvent}}
            v-row
              v-col
                v-btn(block :color="!localEvent.isFinal?'yellow':'grey'" @click="$set(localEvent, 'isFinal', false)").text-none Vorläufige Version
              v-col
                v-btn( block :color="localEvent.isFinal?'green':'grey'" @click="$set(localEvent, 'isFinal', true)").text-none Finale Informationen

            v-row
              v-col
                v-text-field(v-model="localEvent.name" :label="$t('events.name')"  type="text" :rules="[() => !!localEvent.name || $t('events.nameRequired')]")

            v-row
              v-col(:md="6" :sm="12")
                v-text-field(v-model="localEvent.date" :label="$t('events.date')" v-mask="'##.##.####'" type="text" :rules="[() => !!localEvent.date || $t('events.dateRequired')]")
              v-col(:md="6" :sm="12")
                v-text-field(v-model="localEvent.insideOutside"   :label="$t('events.insideOutside')"  type="text")
            v-row
              v-col(:md="6" :sm="6")
                v-text-field(v-model="localEvent.place" :label="$t('events.place')" type="text" )
                  v-row
              v-col(:md="6" :sm="6")
                v-text-field(v-model="localEvent.address" :label="$t('events.address')" )
            v-row
              v-col
                v-text-field(v-model="localEvent.castDescription" :label="$t('events.castDescription')"  type="text")

            v-row
              v-col(:md="12" :sm="12")
                v-textarea(v-model="localEvent.meetArtists" :label="$t('events.meetArtists')")

            v-row
              v-col(:md="12" :sm="12")
                v-text-field(v-model="localEvent.getInArtists" :label="$t('events.getInArtists')"  type="text" )

            v-row
              v-col(:md="12" :sm="12")
                v-textarea(v-model="localEvent.meetTechnicians" :label="$t('events.meetTechnicians')")

            v-row
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.settingUpTime" :label="$t('events.settingUpTime')" type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.soundcheck" :label="$t('events.soundcheck')"  type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.entranceTime" :label="$t('events.entranceTime')"  type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.catering" :label="$t('events.catering')" type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.showTime" :label="$t('events.showTime')" type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.destructionTime" :label="$t('events.destructionTime')" type="text" )
            v-row
              v-col(:md="6" :sm="12")
                v-textarea(v-model="localEvent.contactTechnician" :label="$t('events.contactTechnician')" )

              v-col(:md="6" :sm="12")
                v-textarea(v-model="localEvent.contactProduction" :label="$t('events.contactProduction')" )

            v-row
              v-col(:md="12" :sm="12")
                v-textarea(v-model="localEvent.hotelInfo" :label="$t('events.hotelInfo')")
            v-row
              h3 {{$t('events.misc')}}

            v-row
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.rollUp')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.rollUp === true?'green':'grey'" @click="$set(localEvent, 'rollUp', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.rollUp === false?'red':'grey'" @click="$set(localEvent, 'rollUp', false)").text-none Nein

              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.balloon')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.balloon === true?'green':'grey'" @click="$set(localEvent, 'balloon', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.balloon === false?'red':'grey'" @click="$set(localEvent, 'balloon', false)").text-none Nein
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.shooter')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.shooter === true?'green':'grey'" @click="$set(localEvent, 'shooter', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.shooter === false?'red':'grey'" @click="$set(localEvent, 'shooter', false)").text-none Nein
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.peepholeStand')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.peepholeStand === true?'green':'grey'" @click="$set(localEvent, 'peepholeStand', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.peepholeStand === false?'red':'grey'" @click="$set(localEvent, 'peepholeStand', false)").text-none Nein
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.lightSetUp')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.lightSetUp === true?'green':'grey'" @click="$set(localEvent, 'lightSetUp', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.lightSetUp === false?'red':'grey'" @click="$set(localEvent, 'lightSetUp', false)").text-none Nein
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.completeProduction')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.completeProduction === true?'green':'grey'" @click="$set(localEvent, 'completeProduction', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.completeProduction === false?'red':'grey'" @click="$set(localEvent, 'completeProduction', false)").text-none Nein
            v-row
              //v-col
              //  v-textarea(v-model="localEvent.description" :label="$t('events.description')")
              v-col
                v-textarea(v-model="localEvent.descriptionPerformer" :label="$t('events.descriptionPerformer')")
              v-col
                v-textarea(v-model="localEvent.descriptionProduction" :label="$t('events.descriptionProduction')")
            v-row
              v-col
                v-btn(block color="#8a2387" :disabled="!formValid" @click="submit") {{$t('events.create')}}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { clone } from 'lodash'
import { lightFormat, parseISO } from 'date-fns'
export default {
  name: 'CreateEvent',
  metaInfo () { return { title: this.$t('events.create') } },
  data () {
    const now = new Date()
    return {
      localEvent: {
        name: '',
        date: lightFormat(now, 'dd.MM.yyyy'),
        time: '16:00 Uhr',
        getInArtists: '16:00 Uhr',
        settingUpTime: '16:00 Uhr',
        soundcheck: '16:00 Uhr',
        entranceTime: '16:00 Uhr',
        showTime: '16:00 Uhr',
        destructionTime: '16:00 Uhr',
        catering: '16:00 Uhr',
        place: '',
        description: '',
        descriptionPerformer: '',
        descriptionProduction: ''
      }
    }
  },
  mounted () {
    if (this.$route?.query?.copyFrom) {
      if (this.copyFromEvent === null) {
        this.loadEvent(this.$route.query.copyFrom)
      } else {
        this.copyEventFromEvent(this.copyFromEvent)
      }
    }
  },
  computed: {
    ...mapGetters({ eventById: 'events/byId', events: 'events/asArray' }),
    formValid () {
      if (!this.localEvent.date) return false
      if (!this.localEvent.place) return false
      return true
    },
    copyFromEvent () {
      return this.eventById(this.$route?.query?.copyFrom) || null
    }
  },
  watch: {
    copyFromEvent (value) {
      if (value && this.localEvent.name === '' && this.localEvent.place === '') {
        console.log(this.localEvent, value)
        this.copyEventFromEvent(value)
      }
    }
  },
  methods: {
    ...mapActions({ loadEvent: 'events/getEntry', createEvent: 'events/postEntry' }),
    copyEventFromEvent (value) {
      const newValues = { ...value, assignments: [], date: lightFormat(parseISO(this.copyFromEvent.date), 'dd.MM.yyyy'), description: '' }
      if (newValues['@id']) { delete newValues['@id'] }
      this.$set(this, 'localEvent', newValues)
    },
    submit () {
      const newObject = clone(this.localEvent)
      newObject.date = `${this.localEvent.date}T00:00:00+01:00`

      this.createEvent(newObject).then((localEvent) => {
        this.$awn.success(this.$t('events.saved'))
        this.$router.push({ name: 'EventsDetail', params: { eventId: localEvent.id + '' } })
      }).catch(e => {
        console.error({ e })
        if (e.response.data.detail) {
          this.$awn.alert(this.$t('events.savedError') + e.response.data.detail)
        } else {
          this.$awn.alert(this.$t('events.savedError') + e)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
