<template lang="pug">
.events-list
  v-container(fluid)
    v-row
      v-col
        h1 {{$t('events.heading')}}
          .float-end
            v-btn(color="#8a2387" :to="{name:'EventCreate'}" v-if="hasRole('ROLE_EVENT_CREATE')").text-none
              v-icon.mr-3 fa-plus
              |  {{$t('events.create')}}
    v-row
      v-tabs(v-model="tab" align-with-title background-color="transparent" )
        v-tabs-slider(color="#8a2387")
        v-tab.text-none(key="calendar") {{$t('events.calendar')}}
        v-tab.text-none(key="list") {{$t('events.table')}}
        v-spacer(v-if="tab===1")
        v-btn(v-if="tab===1" @click="showOnlyFutureEvents=!showOnlyFutureEvents").text-none.mt-2.mr-2
          //v-icon.mx-2.mr-4 {{showOnlyFutureEvents?'fa-check':'fa-times'}}
          |   {{showOnlyFutureEvents?$t('events.showOnlyEventsInFuture'):$t('events.showAllEvents')}}
          //v-switch(v-if="tab===1" :label="switchLabel" v-model="showOnlyFutureEvents").mt-2.mr-2

    v-row
      v-tabs-items(v-model="tab").col.background-transparent
        v-tab-item(key="calendar")
          app-events-calendar(:events="localEvents")
        v-tab-item(key="list")
          v-data-table(
            :headers="tableHeaders"
            :loading="isLoading"
            :no-data-text="$t('Keine Veranstaltungen vorhanden')"
            :items="localEvents"
            :sort-by="['sortableDate']"
            :items-per-page="-1"
            show-expand
          )
            template(v-slot:item.actions="{item}")
              v-btn.text-none(:to="{name:'EventsDetail', params:{eventId:item.id+''}}") {{$t('events.actionDetails')}}
            template(v-slot:item.sortableDate="{item}") {{item.date|shortGermanDate}} ({{item.date|formatDistance}})
            template(v-slot:item.userAssignment="{item}")
              div(v-if="getOwnAssignments(item.assignments)?.length<=0") Nur Orga Zugriff
              div(v-for="assignment of getOwnAssignments(item.assignments)")
                app-assignment-state-view(:assignment="assignment")
            template(v-slot:item.assignments="{item}") {{item.assignments.length}} ({{item.assignments|confirmedCount}})
            template(v-slot:item.documents="{item}") {{getDocuments(item).length}}
            template(v-slot:expanded-item="{ headers, item }")
              td(:colspan="headers.length")
                app-event-inline-view(:event-id="extractId(item)")
</template>

<script>
import AppEventsCalendar from '@/modules/Events/components/app-events-calender'
import { mapActions, mapGetters } from 'vuex'
import { formatDistanceToNow } from 'date-fns'
import { locale } from '@/i18n'
import { enUS, de } from 'date-fns/locale'
import { hasRole } from '@/modules/Authentication/helper/Checks'
import { extractId, extractIri, getIdFromIri } from '@/modules/Core/helpers/HydraHelper'
import AppEventInlineView from '@/modules/Events/components/app-event-inline-view.vue'
import AppAssignmentStateView from '@/modules/Assigments/components/app-assignment-state-view.vue'
const locales = { enUS, de }
export default {
  name: 'EventsList',
  components: { AppAssignmentStateView, AppEventInlineView, AppEventsCalendar },
  metaInfo () {
    return { title: this.$t('events.heading') }
  },
  data () {
    return {
      showOnlyFutureEvents: true,
      tab: 1

    }
  },
  watch: {
    showOnlyFutureEvents () {
      this.localLoadEvents()
    }
  },
  filters: {
    confirmedCount (value) {
      // console.log(value)
      return value.filter(e => e.confirmed === 'confirmed').length
    },
    formatDistance (value) {
      return formatDistanceToNow(value, {
        addSuffix: true,
        includeSeconds: true,
        locale: locales[locale.indexOf('de') > -1 ? 'de' : 'enUs']
      })
    }
  },
  methods: {
    ...mapActions({
      loadEvents: 'events/getEntries',
      loadEvent: 'events/getEntry',
      loadAssignments: 'assignments/getEntries',
      loadDocuments: 'documents/getEntries',
      confirmAssignment: 'assignments/confirm',
      declineAssignment: 'assignments/decline'
    }),
    extractIri,
    extractId,
    hasRole,
    localLoadEvents () {
      const now = new Date(new Date().valueOf() - 60 * 60 * 24 * 1000)
      if (this.showOnlyFutureEvents) {
        this.loadEvents({ 'date[after]': now.toISOString() })
      } else {
        this.loadEvents()
      }
    },
    getDocuments (event) {
      return this.getDocumentsByEvent(event)
    },
    getOwnAssignments (assignments) {
      return assignments?.filter(e => getIdFromIri(e.user) === this.loggedInUser.id).map(e => {
        return this.getAssignment(extractIri(e)) || e
      })
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'events/isLoading',
      events: 'events/asArray',
      getUser: 'users/byIRI',
      loggedInUser: 'users/profile',
      getDocumentsByEvent: 'documents/byEvent',
      getAssignment: 'assignments/byIri'
    }),
    tableHeaders () {
      const baseList = [
        {
          text: this.$t('events.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('events.place'),
          align: 'start',
          sortable: true,
          value: 'place'
        }, {
          text: this.$t('events.date'),
          align: 'start',
          sortable: true,
          value: 'sortableDate'
        }]
      if (hasRole('ROLE_ADMIN')) {
        baseList.push({
          text: this.$t('events.assignments'),
          align: 'start',
          sortable: false,
          value: 'assignments'
        }
        /*, {
          text: this.$t('events.documents'),
          align: 'start',
          sortable: false,
          value: 'documents'
        } */)
      } else {
        baseList.push({
          text: this.$t('events.userAssignment'),
          align: 'left',
          width: '360px',
          sortable: false,
          value: 'userAssignment'
        })
      }
      baseList.push({
        text: this.$t('events.actions'),
        align: 'start',
        sortable: false,
        value: 'actions'
      },
      { text: '', value: 'data-table-expand' }
      )
      return baseList
    },
    switchLabel () { return this.$t('events.showOnlyEventsInFuture') },
    localEvents () {
      const now = new Date().valueOf()
      return this.events
        .map(e => ({ ...e, sortableDate: new Date(e.date).valueOf(), date: new Date(e.date) }))
        .filter(e => this.tab === 1 ? this.showOnlyFutureEvents ? e.sortableDate + 60 * 60 * 24 * 1000 >= now : true : true)
    }
  },
  mounted () {
    this.localLoadEvents()
    this.loadDocuments()
  }
}
</script>

<style lang="less">
.theme--light.v-tabs-items.background-transparent{
  background-color: transparent;

  .v-application{
    background-color: transparent;
  }
  .theme--light.v-data-table{
    background-color: transparent;

  }
}

</style>
