<template lang="pug">
.detail-event
  v-container(v-if="localEvent && localEvent.name" fluid)
    v-row(justify="space-between")
      v-col(sm="auto")
        h1 "{{localEvent.name}}"
          small.ml-4 am
          span.mx-3.text-underline {{localEvent.date|shortGermanDate}}
          small in
          span.mx-3.text-underline {{localEvent.place}}
      v-col.text-right
        //v-tooltip(left)
        //  template(v-slot:activator="{ on, attrs }")
        //    v-btn(
        //      :href="eventSheetUrl"
        //      v-bind="attrs"
        //      v-on="on"
        //      color="blue"
        //      target="_blank"
        //    ).text-none.mr-2
        //      v-icon.mr-2 fa-print
        //      | EventSheet öffnen
        //  span Übersicht ausdrucken
        v-btn(icon :to="{name:'EventsEdit', params:{eventId}}" v-if="hasRole('ROLE_EVENT_UPDATE')")
          v-icon fa-pen
        v-btn(icon :to="{name:'EventCreate', params:{eventId}, query:{copyFrom:eventId}}" v-if="hasRole('ROLE_EVENT_UPDATE')")
          v-icon fa-copy
  v-container(v-else)
    v-alert Diese Veranstaltung existiert nicht.

  v-container(fluid v-if="localEvent && localEvent.name" )
    v-row
      v-col
        app-events-base-information(:eventId="eventId").my-2
    v-row
      v-col
        app-events-assignments-list(:eventId='eventId')

      v-col
        app-events-documents-list(:eventId='eventId')

    router-view

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import AppEventsAssignmentLine from '@/modules/Assigments/components/app-events-assignment-line.vue'
import AppEventsAssignmentCreateLine from '@/modules/Assigments/components/app-events-assignment-create-line.vue'
import AppDocumentsCreateLine from '@/modules/Documents/components/app-documents-create-line'
import AppDocumentsList from '@/modules/Documents/components/app-documents-list'
import AppDocumentsCreateButton from '@/modules/Documents/components/app-documents-create-button'
import { hasRole } from '@/modules/Authentication/helper/Checks'
import AppEventsAssignmentsList from '@/modules/Assigments/components/app-assignments-list.vue'
import AppEventsBaseInformation from '@/modules/Events/components/app-events-base-information.vue'
import AppEventsDocumentsList from '@/modules/Documents/components/app-events-documents-list.vue'

export default {
  name: 'DetailEvent',
  components: { AppEventsDocumentsList, AppEventsBaseInformation, AppEventsAssignmentsList, AppDocumentsCreateButton, AppDocumentsList, AppDocumentsCreateLine, AppEventsAssignmentCreateLine, AppEventsAssignmentLine },
  props: ['eventId'],
  metaInfo () {
    return { title: this.$t('events.detail') + ' "' + (this.localEvent ? this.localEvent.name : 'loading') + '"' }
  },
  data () {
    return {
    }
  },
  mounted () {
    this.loadEvent(this.eventId)
    this.loadUsers()
    this.loadAssignments({ event: this.eventId })
    this.loadDocuments({ event: this.eventId })
  },
  methods: {
    ...mapActions({
      loadUsers: 'users/getEntries',
      loadEvent: 'events/getEntry',
      loadAssignments: 'assignments/getEntries',
      loadDocuments: 'documents/getEntries'
    }),
    hasRole
  },
  computed: {
    ...mapGetters({
      isLoading: 'events/isLoading',
      assignmentByEvent: 'assignments/byEvent',
      eventById: 'events/byId'
    }),
    localEvent () {
      return cloneDeep(this.eventById(this.eventId))
    }
  }

}
</script>

<style scoped lang="less">
</style>
