<template lang="pug">
  v-list-item
    v-list-item-content
      v-container
        v-row
          v-col
            v-combobox(
              v-model="newAssigment.type"
              :items="assignmentTypes"
              :key="'index'"
              :label="$t('assignments.type')"
              outlined
            )
          v-col
            v-select(
              v-model="newAssigment.user"
              :items="possibleAssigmentPersons"
              :label="$t('assignments.person')"
              item-text="displayName"
              item-value="@id"
              outlined
              )
    v-list-item-action.pb-8
      v-btn(icon @click="save" color="success" :disabled="!formIsValid")
        v-icon fa-save

</template>

<script>
import { assignmentTypes } from '@/consts'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
export default {
  name: 'app-events-assignment-create-line',
  data () {
    return {
      assignmentTypes,
      newAssigment: { type: null, user: null }
    }
  },
  props: {
    event: Object,
    assignment: Object
  },
  computed: {
    ...mapGetters({ getUsers: 'users/asList' }),
    canSave () { return this.create },
    formIsValid () { return this.newAssigment.type !== null && !!this.newAssigment.user },
    possibleAssigmentPersons () { return this.getUsers.map(e => ({ ...e, displayName: e.fullname ? e.fullname : e.username })) }
  },

  methods: {
    ...mapActions({ postAssigment: 'assignments/postEntry' }),
    save () {
      const newAssignment = cloneDeep(this.newAssigment)
      newAssignment.documents = []
      newAssignment.confirmed = 'unset'
      newAssignment.event = this.event['@id']
      this.postAssigment(newAssignment)
    },
    assigmentTypeName (item) {
      return item.name
    },
    getType (typeName) {
      return this.assignmentTypes.find(e => e.name === typeName)
    }
  }
}
</script>

<style scoped>

</style>
