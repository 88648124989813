<template lang="pug">
  v-dialog(v-model='dialog' max-width='95%' )
    v-card(:loading="isLoading")
      // | {{user}} {{event}} {{assignment}}
      v-card-title.text-h5(v-if="event") Neue Teilnahme anlegen
        v-spacer
      v-card-text(v-if="hasRole('ROLE_DOCUMENT_UPDATE')" )
        v-row
          v-col
            v-combobox(
              v-model="newAssigment.type"
              :items="assignmentTypes"
              :key="'index'"
              :label="$t('assignments.type')"
              outlined
            )
          v-col
            v-select(
              v-model="newAssigment.user"
              :items="possibleAssigmentPersons"
              :label="$t('assignments.person')"
              item-text="displayName"
              item-value="@id"
              outlined
            )

        v-row
            v-col
              v-textarea(v-model="newAssigment.description" :label="$t('events.description')" :hint="$t('events.descriptionHint')" auto-grow)
            v-col
                h3.pb-3 {{$t('events.descriptionPreview')}}
                p(v-html="description")
      v-card-actions
        v-btn(block @click="save" color="success" :disabled="!formIsValid")
          v-icon.mr-2 fa-save
          | Speichern und Einladen
          // app-events-assignment-create-line(:event="localEvent" )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppDocumentsCreateLine from '@/modules/Documents/components/app-documents-create-line'
import AppDocumentsList from '@/modules/Documents/components/app-documents-list'
import AppDocumentsCreateButton from '@/modules/Documents/components/app-documents-create-button'
import { hasRole } from '@/modules/Authentication/helper/Checks'
import { cloneDeep } from 'lodash'
import { assignmentTypes } from '@/consts'

export default {
  name: 'Assignment',
  components: { AppDocumentsCreateButton, AppDocumentsList, AppDocumentsCreateLine },
  props: ['eventId'],
  metaInfo () {
    return { title: this.$t('events.description') + ' "' + (this.user ? (this.user.fullname ? this.user.fullname : this.user.username) : '') + '" an ' + (this.event ? this.event.name : 'loading') }
  },

  data () {
    return {
      assignmentTypes,
      dialog: true,
      newAssigment: { type: null, user: null }

    }
  },
  computed: {
    ...mapGetters({
      isLoadingUsers: 'users/isLoading',
      isLoadingEvents: 'events/isLoading',
      getAssignment: 'assignments/detail',
      getDocuments: 'documents/byAssignment',
      getEvent: 'events/byId',
      getUser: 'users/byIRI',
      getUsers: 'users/asList'

    }),
    formIsValid () { return this.newAssigment.type !== null && !!this.newAssigment.user },
    possibleAssigmentPersons () { return this.getUsers.map(e => ({ ...e, displayName: e.fullname ? e.fullname : e.username })) },
    isLoading () { return this.isLoadingEvents || this.isLoadingUsers },
    event () { return this.getEvent(this.eventId) },
    description () {
      if (!this.newAssigment || !this.newAssigment.description) return null
      return this.marked(this.newAssigment.description)
    }
  },
  mounted () { },
  watch: {
    dialog (val) {
      if (val === false) {
        this.$router.push({ name: 'EventsDetail', params: this.$route.params })
      }
    }
  },
  methods: {
    ...mapActions({
      postAssigment: 'assignments/postEntry'
    }),
    hasRole,
    save () {
      const newAssignment = cloneDeep(this.newAssigment)
      newAssignment.documents = []
      newAssignment.confirmed = 'unset'
      newAssignment.event = this.event['@id']
      this.postAssigment(newAssignment).then(() => {
        this.dialog = false
        this.$awn.success(this.$t('assignments.new'))
        this.$router.push({ name: 'EventsDetail', params: { eventId: this.eventId } })
      })
    }
  }
}
</script>

<style scoped>
.thin-rounded-border{
  border:thin solid lightgrey;
  border-radius: 5px;
  min-height: 30px;
  width: 100%;
}
</style>
