<template lang="pug">
  .personal-assignment
    v-card(:loading="isLoading" width="100%" )
      v-card-title
        span.text-h5.text.text-uppercase(v-if="assignment") Deine Teilnahme als
          span.text-underline.mx-2 {{assignment.type}}

      v-card-text
        p(v-html="description")
        app-events-assignment-line(:event="event" :assignment="assignment" short)
        .text-h5 Deine Dokumente
        app-documents-list(v-if="documents && documents.length>0" :documents="documents")
        span(v-else) Keine Dokumente verfügbar.

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppEventsAssignmentLine from '@/modules/Assigments/components/app-events-assignment-line.vue'
import AppDocumentsList from '@/modules/Documents/components/app-documents-list.vue'
import { extractId } from '@/modules/Core/helpers/HydraHelper'

export default {
  components: { AppDocumentsList, AppEventsAssignmentLine },
  props: ['assignment'],
  name: 'app-personal-assignment',
  data () {
    return { }
  },
  created () {
    this.loadDocumentsOfAssignment()
  },
  methods: {
    ...mapActions({ LoadDocuments: 'documents/getEntries' }),
    loadDocumentsOfAssignment () {
      this.LoadDocuments({ assignment: extractId(this.assignment) })
    }
  },
  computed: {
    ...mapGetters({
      isLoadingUsers: 'users/isLoading',
      isLoadingEvents: 'events/isLoading',
      getDocuments: 'documents/byAssignment',
      getEventByIri: 'events/byIri'
    }),
    documents () { return this.getDocuments(this.assignment) },

    isLoading () { return this.isLoadingEvents || this.isLoadingUsers },
    description () {
      if (!this.assignment || !this.assignment.description) return null
      return this.marked(this.assignment.description)
    },
    event () {
      return this.getEventByIri(this.assignment.event)
    }
  },
  watch: {
  }
}
</script>

<style scoped>

</style>
