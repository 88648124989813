<template lang="pug">
.create-event
  v-container(fluid)
    v-row(v-if="!isLoading && !localEvent")
      v-col
        v-alert(type="warning") Diese Veranstaltung existiert nicht.

    v-row(v-else)
      v-col
        v-card(:loading="isLoading")
          v-card-title(v-if="localEvent")
            span.text-h5.text-uppercase {{$t('events.update')}}
            v-spacer
            v-dialog(transition='dialog-top-transition' max-width='600' v-if="hasRole('ROLE_EVENT_DELETE') ")
              template(v-slot:activator='{ on, attrs }')
                v-btn(v-bind='attrs' v-on='on' color="error" )
                  v-icon.mr-2 fa-trash
                  | Löschen
              template(v-slot:default='dialog')
                v-card()
                  v-toolbar(color='error')
                    h3 {{$t('core.sure')}}
                  v-card-actions.justify-end
                    v-btn(@click='dialog.value = false; deleteEvent();' color="error")
                      v-icon.mr-2 fa-trash
                      | {{$t('core.delete')}}
                    v-btn( text @click='dialog.value = false')
                      v-icon.mr-2 fa-times
                      | {{$t('core.cancel')}}

          v-card-text(v-if="localEvent")
            v-row
              v-col
                v-btn(block :color="!localEvent.isFinal?'yellow':'grey'" @click="$set(localEvent, 'isFinal', false)").text-none Vorläufige Version
              v-col
                v-btn( block :color="localEvent.isFinal?'green':'grey'" @click="$set(localEvent, 'isFinal', true)").text-none Finale Informationen

            v-row
              v-col
                v-text-field(v-model="localEvent.name" :label="$t('events.name')"  type="text" :rules="[() => !!localEvent.name || $t('events.nameRequired')]")

            v-row
              v-col(:md="6" :sm="12")
                v-text-field(v-model="localEvent.date" :label="$t('events.date')" v-mask="'##.##.####'" type="text" :rules="[() => !!localEvent.date || $t('events.dateRequired')]")
              v-col(:md="6" :sm="12")
                v-text-field(v-model="localEvent.insideOutside"   :label="$t('events.insideOutside')"  type="text")
            v-row
              v-col(:md="6" :sm="6")
                v-text-field(v-model="localEvent.place" :label="$t('events.place')" type="text" )
                  v-row
              v-col(:md="6" :sm="6")
                v-text-field(v-model="localEvent.address" :label="$t('events.address')" )

            v-row
              v-col
                v-text-field(v-model="localEvent.castDescription" :label="$t('events.castDescription')"  type="text")

            v-row
              v-col(:md="12" :sm="12")
                v-textarea(v-model="localEvent.meetArtists" :label="$t('events.meetArtists')")

            v-row
              v-col(:md="12" :sm="12")
                v-text-field(v-model="localEvent.getInArtists" :label="$t('events.getInArtists')"  type="text" )

            v-row
              v-col(:md="12" :sm="12")
                v-textarea(v-model="localEvent.meetTechnicians" :label="$t('events.meetTechnicians')")

            v-row
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.settingUpTime" :label="$t('events.settingUpTime')" type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.soundcheck" :label="$t('events.soundcheck')"  type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.entranceTime" :label="$t('events.entranceTime')"  type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.catering" :label="$t('events.catering')" type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.showTime" :label="$t('events.showTime')" type="text" )
              v-col(:md="3" :sm="12")
                v-text-field(v-model="localEvent.destructionTime" :label="$t('events.destructionTime')" type="text" )
            v-row
              v-col(:md="6" :sm="12")
                v-textarea(v-model="localEvent.contactTechnician" :label="$t('events.contactTechnician')" )

              v-col(:md="6" :sm="12")
                v-textarea(v-model="localEvent.contactProduction" :label="$t('events.contactProduction')" )

            v-row
              v-col(:md="12" :sm="12")
                v-textarea(v-model="localEvent.hotelInfo" :label="$t('events.hotelInfo')")
            v-row
              h3 {{$t('events.misc')}}

            v-row
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.rollUp')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.rollUp === true?'green':'grey'" @click="$set(localEvent, 'rollUp', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.rollUp === false?'red':'grey'" @click="$set(localEvent, 'rollUp', false)").text-none Nein

              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.balloon')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.balloon === true?'green':'grey'" @click="$set(localEvent, 'balloon', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.balloon === false?'red':'grey'" @click="$set(localEvent, 'balloon', false)").text-none Nein
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.shooter')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.shooter === true?'green':'grey'" @click="$set(localEvent, 'shooter', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.shooter === false?'red':'grey'" @click="$set(localEvent, 'shooter', false)").text-none Nein
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.peepholeStand')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.peepholeStand === true?'green':'grey'" @click="$set(localEvent, 'peepholeStand', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.peepholeStand === false?'red':'grey'" @click="$set(localEvent, 'peepholeStand', false)").text-none Nein
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.lightSetUp')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.lightSetUp === true?'green':'grey'" @click="$set(localEvent, 'lightSetUp', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.lightSetUp === false?'red':'grey'" @click="$set(localEvent, 'lightSetUp', false)").text-none Nein
              v-col(:md="2" :sm="4")
                .value-box
                  .label {{$t('events.completeProduction')}}
                  v-row.value(dense)
                    v-col: v-btn(block :color="localEvent.completeProduction === true?'green':'grey'" @click="$set(localEvent, 'completeProduction', true)").text-none Ja
                    v-col: v-btn(block :color="localEvent.completeProduction === false?'red':'grey'" @click="$set(localEvent, 'completeProduction', false)").text-none Nein
            v-row
              //v-col
              //  v-textarea(v-model="localEvent.description" :label="$t('events.description')")
              v-col
                v-textarea(v-model="localEvent.descriptionPerformer" :label="$t('events.descriptionPerformer')")
              v-col
                v-textarea(v-model="localEvent.descriptionProduction" :label="$t('events.descriptionProduction')")
            v-row
              v-col
                v-btn(block color="#8a2387" :disabled="!formValid" @click="submit") {{$t('events.save')}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { clone, cloneDeep } from 'lodash'
import { lightFormat, parseISO } from 'date-fns'
import { hasRole } from '@/modules/Authentication/helper/Checks'
export default {
  name: 'EditEvent',
  props: ['eventId'],
  metaInfo () { return { title: this.$t('events.update') } },
  data () {
    return {
      eventError: false,
      localEvent: {}
    }
  },
  mounted () {
    this.updateLocalEvent()
  },
  watch: {
    event (val) {
      this.updateLocalEvent()
    }
  },
  computed: {
    ...mapGetters({
      getEvent: 'events/detail',
      isLoading: 'events/isLoading'
    }),
    formValid () {
      if (!this.localEvent.date) return false
      if (!this.localEvent.place) return false
      return true
    },
    preview () {
      if (!this.localEvent.description) return ''
      return this.marked(this.localEvent.description)
    },
    event () {
      const event = this.getEvent(this.eventId)
      if (!event && !this.eventError) {
        this.loadEventWithErrorHandling()
      }
      return event
    }
  },
  methods: {
    ...mapActions({
      loadEvent: 'events/getEntry',
      updateEvent: 'events/putEntry',
      deleteEntry: 'events/deleteEntry'
    }),
    hasRole,
    deleteEvent () {
      this.deleteEntry(this.localEvent).then(() => {
        this.$awn.success(this.$t('events.deleted'))
        this.$router.push({ name: 'EventsList' })
      }).catch(e => {
        console.error({ e })
        if (e.response.data.detail) {
          this.$awn.alert(this.$t('events.deletedError') + e.response.data.detail)
        } else {
          this.$awn.alert(this.$t('events.deletedError') + e)
        }
      })
    },
    loadEventWithErrorHandling () {
      this.loadEvent(this.eventId).catch(() => {
        this.eventError = true
      })
    },
    updateLocalEvent () {
      this.$set(this, 'localEvent', this.transformDate(this.event))
    },
    transformDate (event) {
      if (!event) return null
      // console.log(event.date)
      const date = parseISO(event.date)
      const eventClone = cloneDeep(event)
      eventClone.date = lightFormat(date, 'dd.MM.yyyy')
      if (!eventClone.description) {
        eventClone.description = ''
      }
      if (!eventClone.descriptionPerformer) {
        eventClone.descriptionPerformer = ''
      }
      if (!eventClone.descriptionProduction) {
        eventClone.descriptionProduction = ''
      }

      return eventClone
    },
    submit () {
      const parts = this.localEvent.date.split('.')
      const newDate = `${parts[2]}-${parts[1]}-${parts[0]}T00:00:00+00:00`
      const newObject = clone(this.localEvent)
      if (newObject.assignments) {
        delete newObject.assignments
      }
      if (newObject.description) {
        newObject.description = ''
      }
      newObject.date = newDate
      this.updateEvent(newObject).then(() => {
        this.$awn.success(this.$t('events.saved'))
        this.$router.push({ name: 'EventsDetail', params: { eventId: this.eventId } })
      }).catch(e => {
        console.error({ e })
        if (e.response.data.detail) {
          this.$awn.alert(this.$t('events.savedError') + e.response.data.detail)
        } else {
          this.$awn.alert(this.$t('events.savedError') + e)
        }
      })
    }
  }

}
</script>

<style scoped lang="less">
.value-box{
  padding-top: 20px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 5px;
  border: thin solid lightgray;
  position: relative;
  .label{
    color: grey;
    font-size: small;
    position: absolute;
    top: 0;
    left: 0.5rem;
  }
  .value{

  }
}
</style>
