<template lang="pug">
.createDocument
  h2 Neue Datei hochladen
  v-file-input(type="file" @change="selectFile" ref="fileUpload" )

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'app-documents-create-line',
  props: {
    event: Object,
    assignment: Object
  },
  methods: {
    ...mapActions({ upload: 'documents/uploadFile' }),
    selectFile (file) {
      if (this.assignment) {
        this.upload({ file, assignment: this.assignment['@id'] }).then((data) => {
          // console.log(data.originalName)
          this.$awn.success('Datei ' + data.originalName + ' erfolgreich hochgeladen.')
          this.$refs.fileUpload.$refs.input.value = null
        })
      } else if (this.event) {
        this.upload({ file, event: this.event['@id'] }).then((data) => {
          // console.log(data.originalName)
          this.$awn.success('Datei ' + data.originalName + ' erfolgreich hochgeladen.')
          this.$refs.fileUpload.$refs.input.value = null
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
