<template lang="pug">
  .events-documents-list
    v-card(:loading="isLoading")
      v-card-title
        v-container
          v-row
            span.text-h5.text-uppercase {{$t('events.documents')}}
            v-spacer
            app-documents-create-button(:event="localEvent" v-if="hasRole('ROLE_DOCUMENT_CREATE') " )
          v-row
            .text-sm-subtitle-1 {{$t('events.documentsHint')}}
          v-row
            v-col(v-if="localDocuments && localDocuments.length>0")
              app-documents-list(:documents="localDocuments")
            v-col(v-else).text-center.text-sm-subtitle-2
              span Es existieren keine Dokumente für diese Veranstaltung
</template>

<script>
import { mapGetters } from 'vuex'
import AppEventsAssignmentCreateLine from '@/modules/Assigments/components/app-events-assignment-create-line.vue'
import AppEventsAssignmentLine from '@/modules/Assigments/components/app-events-assignment-line.vue'
import { hasRole } from '@/modules/Authentication/helper/Checks'
import { cloneDeep } from 'lodash'
import AppDocumentsCreateButton from '@/modules/Documents/components/app-documents-create-button.vue'
import AppDocumentsList from '@/modules/Documents/components/app-documents-list.vue'

export default {
  components: { AppDocumentsList, AppDocumentsCreateButton, AppEventsAssignmentLine, AppEventsAssignmentCreateLine },
  props: ['eventId'],
  name: 'app-events-documents-list',
  data () { return { } },
  created () { },
  methods: { hasRole },
  computed: {
    ...mapGetters({
      eventById: 'events/byId',
      getDocuments: 'documents/byEventId',
      isLoading: 'documents/isLoading'
    }),
    localEvent () {
      return cloneDeep(this.eventById(this.eventId))
    },
    localDocuments () {
      return this.getDocuments(this.eventId)
    }
  },
  watch: { }
}
</script>

<style scoped>

</style>
