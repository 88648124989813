<template lang="pug">
  .personal-assignments-list
    //pre {{localAssignments}}
    app-personal-assignment(:assignment="assignment" v-for="assignment of localAssignments" :key="extractIri(assignment)").my-2

</template>

<script>
import { mapGetters } from 'vuex'
import AppEventsAssignmentCreateLine from '@/modules/Assigments/components/app-events-assignment-create-line.vue'
import AppEventsAssignmentLine from '@/modules/Assigments/components/app-events-assignment-line.vue'
import { hasRole } from '@/modules/Authentication/helper/Checks'
import { cloneDeep } from 'lodash'
import AppPersonalAssignment from '@/modules/Assigments/components/app-personal-assignment.vue'
import { extractId, extractIri } from '@/modules/Core/helpers/HydraHelper'

export default {
  components: { AppPersonalAssignment, AppEventsAssignmentLine, AppEventsAssignmentCreateLine },
  props: ['eventId'],
  name: 'app-personal-assignments',
  data () { return { } },
  created () { },
  methods: { hasRole, extractIri },
  computed: {
    ...mapGetters({
      getEventByIri: 'events/byIri',
      isLoadingAssignments: 'assignments/isLoading',
      assignmentsOfEvent: 'assignments/byEventId',
      eventById: 'events/byId',
      loggedInUser: 'users/profile'
    }),
    localEvent () {
      return cloneDeep(this.eventById(this.eventId))
    },
    localAssignments () {
      return this.assignmentsOfEvent(this.eventId).filter(e => extractId(this.loggedInUser) === extractId(e.user))
    }
  },
  watch: {
  }
}
</script>

<style scoped>

</style>
