<template lang="pug">
  .event-assignments-list
    v-card(:loading="isLoadingAssignments")
      v-card-title
        span.text-h5.text-uppercase {{$t('events.assignments')}}
        v-spacer
        app-event-send-notification-btn(:eventId="eventId" role="ROLE_USER" ) Benachrichtigung an alle versenden

      v-expand-transition
        v-card-text(v-show="collapsed")
          app-events-assignment-line(:short="short" v-if="localAssignments.userAssignment" :assignment="localAssignments.userAssignment" :event="localEvent")

          v-btn(block @click="setCollapsed(false)").text-none {{$t('core.showMore')}}
      v-expand-transition
        v-card-text(v-show="!collapsed" v-if="localAssignments")

          template(v-if="localAssignments.unassigned?.length>0")
            h3.text-uppercase {{$t('events.groupUnassigned')}}
            v-list
              app-events-assignment-line(:short="short" v-if="localAssignments.unassigned" v-for="assignment of localAssignments.unassigned" :key="assignment.id" :assignment="assignment" :event="localEvent")
          template(v-if="localAssignments.performer?.length>0")
            .my-2
              v-row
                v-col
                  h3.text-lila.text-uppercase {{$t('events.groupPerformer')}}
                v-col.text-right
                  app-event-send-notification-btn(:eventId="eventId" role="ROLE_PERFORMER") Benachrichtigung an Künstler/in versenden

              v-list
                app-events-assignment-line(:short="short" v-if="localAssignments.performer" v-for="assignment of localAssignments.performer" :key="assignment.id" :assignment="assignment" :event="localEvent")
          template(v-if="localAssignments.technicianLead?.length>0")
            .my-2
              v-row
                v-col
                  h3.text-green.text-uppercase {{$t('events.groupTechnicianLead')}}
                v-col.text-right(v-if="currentUserIsAdmin")
                  app-event-send-notification-btn(:eventId="eventId" role="ROLE_TECHNICIAN_LEAD") Benachrichtigung an Produktionsleitung versenden
              v-list
                app-events-assignment-line(:short="short" v-if="localAssignments.technicianLead" v-for="assignment of localAssignments.technicianLead" :key="assignment.id" :assignment="assignment" :event="localEvent")
          template(v-if="localAssignments.technician?.length>0")
            .my-2
              v-row
                v-col
                  h3.text-blue.text-uppercase {{$t('events.groupTechnician')}}
                v-col.text-right
                  app-event-send-notification-btn(:eventId="eventId" role="ROLE_TECHNICIAN") Benachrichtigung an Techniker/in versenden
              v-list
                app-events-assignment-line(:short="short" v-if="localAssignments.technician" v-for="assignment of localAssignments.technician" :key="assignment.id" :assignment="assignment" :event="localEvent")
          v-btn(:to="{name:'EventsListNewAssignment', params:{eventId: eventId}}" v-if="hasRole('ROLE_ASSIGNMENT_CREATE')" block).text-none
            v-icon(small).mr-2 fa-plus
            | Teilnehmer hinzufügen
          v-btn(block @click="setCollapsed(true)").text-none.mt-3 {{$t('core.showLess')}}

</template>

<script>
import { mapGetters } from 'vuex'
import AppEventsAssignmentCreateLine from '@/modules/Assigments/components/app-events-assignment-create-line.vue'
import AppEventsAssignmentLine from '@/modules/Assigments/components/app-events-assignment-line.vue'
import {
  currentUserIsAdmin,
  hasRole,
  isPerformer,
  isTechnician,
  isTechnicianLead
} from '@/modules/Authentication/helper/Checks'
import { cloneDeep } from 'lodash'
import AppEventSendNotificationBtn from '@/modules/Events/components/app-event-send-notification-btn.vue'

export default {
  components: { AppEventSendNotificationBtn, AppEventsAssignmentLine, AppEventsAssignmentCreateLine },
  props: {
    eventId: String,
    short: Boolean
  },
  name: 'app-assignments-list',
  data () {
    return {
      collapsed: true
    }
  },
  created () {
  },
  methods: {
    currentUserIsAdmin,
    hasRole,
    setCollapsed (value) {
      this.$set(this, 'collapsed', value)
    }
  },
  computed: {
    ...mapGetters({
      getEventByIri: 'events/byIri',
      isLoadingAssignments: 'assignments/isLoading',
      assignmentsOfEvent: 'assignments/byEventId',
      eventById: 'events/byId',
      getUserByIri: 'users/byIRI',
      currentLoggedInUser: 'users/profile'

    }),
    localEvent () {
      return cloneDeep(this.eventById(this.eventId))
    },
    localAssignments () {
      return this.assignmentsOfEvent(this.eventId).reduce((previousValue, currentValue) => {
        const user = this.getUserByIri(currentValue.user)
        if (user && this.currentLoggedInUser && user.id === this.currentLoggedInUser.id) {
          previousValue.userAssignment = currentValue
        }
        // previousValue.push(currentValue)
        if (isTechnicianLead(user)) {
          previousValue.technicianLead.push(currentValue)
        } else if (isPerformer(user)) {
          previousValue.performer.push(currentValue)
        } else if (isTechnician(user)) {
          previousValue.technician.push(currentValue)
        } else {
          previousValue.unassigned.push(currentValue)
        }
        return previousValue
      }, { performer: [], technician: [], technicianLead: [], unassigned: [], userAssignment: null })
    }
  },
  watch: {
    currentUserIsAdmin (value) {
      this.$set(this, 'collapsed', !value)
    }
  }
}
</script>

<style scoped>
.border-left-lila{
  border-left: solid rgb(138, 35, 135) 2px;
}
.text-lila{
  color:rgb(138, 35, 135);
}
.text-green{
  color: green;
}
.border-left-blue{
  border-left: solid #2196f3 2px;
}
.text-blue{
  color:#2196f3;
}
</style>
