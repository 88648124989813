<template lang="pug">
.createDocument
  v-btn(@click="startSelect")
    v-icon {{isLoading?'fa-spin fa-spinner':'fa-upload'}}
  .hidden-input
    v-file-input(type="file" @change="selectFile" ref="fileUpload" )

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'app-documents-create-button',
  props: {
    event: Object,
    assignment: Object
  },
  computed: {
    ...mapGetters({ isLoading: 'documents/isLoading' })
  },
  methods: {
    ...mapActions({ upload: 'documents/uploadFile' }),
    startSelect () {
      this.$refs.fileUpload.$refs.input.click()
    },
    selectFile (file) {
      if (this.assignment) {
        this.upload({ file, assignment: this.assignment['@id'] }).then((data) => {
          // console.log(data.originalName)
          this.$awn.success('Datei ' + data.originalName + ' erfolgreich hochgeladen.')
          this.$refs.fileUpload.$refs.input.value = null
        })
      } else if (this.event) {
        this.upload({ file, event: this.event['@id'] }).then((data) => {
          // console.log(data.originalName)
          this.$awn.success('Datei ' + data.originalName + ' erfolgreich hochgeladen.')
          this.$refs.fileUpload.$refs.input.value = null
        })
      }
    }
  }
}
</script>

<style scoped>
.hidden-input{
  display: none;
}
</style>
