<template lang="pug">
.listDocument
  v-list(subheader two-line)
    v-list-item(v-for="document of documents" :key="document['@id']")
      v-list-item-avatar
        v-icon fa-file
      v-list-item-content
        v-list-item-title {{document.originalName}}
        v-list-item-subtitle {{document.size|bytes}}
      v-list-item-action
        span
          v-btn(icon text)
            v-icon(color="blue" @click="downloadDocument(document)") fa-download
          v-btn(icon v-if="hasRole('ROLE_DOCUMENT_DELETE') " text)
            v-icon(color="red" @click="deleteDocument(document)") fa-trash

</template>

<script>
import { mapActions } from 'vuex'
import { hasRole } from '@/modules/Authentication/helper/Checks'
import { extractIri } from '@/modules/Core/helpers/HydraHelper'
import { getFullPathURLByIri } from '@/modules/Core/helpers/RequestHelper'

export default {
  name: 'app-documents-list',
  props: {
    event: Object,
    documents: Array
  },
  methods: {
    ...mapActions({
      readDocument: 'documents/getEntry',
      removeDocument: 'documents/deleteEntry'
    }),
    hasRole,
    deleteDocument (document) {
      this.$awn.confirm('Wollen Sie diese Datei wirklich löschen?', () => {
        this.removeDocument(document).then(() => {
          this.$awn.success('Erfolgreich gelöscht.')
        })
      }, () => {}, {
        labels: {
          confirm: 'Achtung!',
          confirmOk: 'Ja, löschen',
          confirmCancel: 'Abbrechen'
        }
      })
    },
    downloadDocument (document) {
      // TODO add apiurl before
      // console.log(document, process.env, getFullPathURLByIri(extractIri(document)))
      window.open(getFullPathURLByIri(extractIri(document)), '_blank')
    }
  }
}
</script>

<style scoped>

</style>
