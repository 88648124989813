<template lang="pug">
  .event-base-information
    v-card
      v-card-title
        span.text-h5.text-uppercase {{$t('events.descriptionHeading')}}
        v-spacer
        span().text-none.mx-2 {{localEvent.isFinal?'Finale Informationen':'Vorläufige Version!'}}
        v-spacer
        v-btn(
          :href="eventSheetUrl"
          color="blue"
          target="_blank"
        ).text-none.mr-2
          v-icon.mr-2 fa-print
          | EventSheet öffnen
      v-expand-transition
        v-card-text(v-show="collapsed")
          v-btn(block @click="setCollapsed(false)").text-none  {{$t('core.showMore')}}
      v-expand-transition
        v-card-text(v-show="!collapsed")
          v-row
            v-col(:md="6"  :sm="12" cols="12")
              .value-box
                .label  {{$t('events.date')}}
                .value(v-if="localEvent.date") {{localEvent.date|shortGermanDate}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="6"  :sm="12" cols="12")
              .value-box
                .label {{$t('events.insideOutside')}}
                .value(v-if="localEvent.insideOutside") {{localEvent.insideOutside}}
                .value(v-else) &lt;nicht angegeben&gt;
          v-row
            v-col(:md="6" :sm="12")
              .value-box
                .label {{$t('events.place')}}
                .value(v-if="localEvent.place") {{localEvent.place}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="6" :sm="12")
              .value-box
                .label {{$t('events.address')}}
                .value(v-if="localEvent.address") {{localEvent.address|nl2Comma}}
                .value(v-else) &lt;nicht angegeben&gt;
          v-row()
            v-col
              .value-box
                .label {{$t('events.castDescription')}}
                .value(v-if="localEvent.castDescription") {{localEvent.castDescription}}
                .value(v-else) &lt;nicht angegeben&gt;

          v-row(v-if="isPerformer")
            v-col
              .value-box
                .label {{$t('events.meetArtists')}}
                .value(v-if="localEvent.meetArtists" v-html="markedFkt(localEvent.meetArtists)")
                .value(v-else) &lt;nicht angegeben&gt;
          v-row
            v-col
              .value-box
                .label {{$t('events.getInArtists')}}
                .value(v-if="localEvent.getInArtists") {{localEvent.getInArtists}}
                .value(v-else) &lt;nicht angegeben&gt;
          v-row(  v-if="isTechnician")
            v-col
              .value-box
                .label {{$t('events.meetTechnicians')}}
                .value(v-if="localEvent.meetTechnicians" v-html="markedFkt(localEvent.meetTechnicians)")
                .value(v-else) &lt;nicht angegeben&gt;
          v-row
            v-col(:md="4" :sm="12")
              .value-box
                .label {{$t('events.settingUpTime')}}
                .value(v-if="localEvent.settingUpTime") {{localEvent.settingUpTime}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4" :sm="12")
              .value-box
                .label {{$t('events.soundcheck')}}
                .value(v-if="localEvent.soundcheck") {{localEvent.soundcheck}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4" :sm="12")
              .value-box
                .label {{$t('events.entranceTime')}}
                .value(v-if="localEvent.entranceTime") {{localEvent.entranceTime}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4" :sm="12")
              .value-box
                .label {{$t('events.catering')}}
                .value(v-if="localEvent.catering") {{localEvent.catering}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4"  :sm="6" cols="12")
              .value-box
                .label {{$t('events.showTime')}}
                .value(v-if="localEvent.showTime") {{localEvent.showTime}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4"  :sm="6" cols="12")
              .value-box
                .label {{$t('events.destructionTime')}}
                .value(v-if="localEvent.destructionTime") {{localEvent.destructionTime}}
                .value(v-else) &lt;nicht angegeben&gt;
          v-row
            v-col(:md="6"  cols="12"  v-if="isTechnician")
              .value-box
                .label {{$t('events.contactTechnician')}}
                .value(v-if="localEvent.contactTechnician" v-html="markedFkt(localEvent.contactTechnician)")
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="6"  cols="12"  v-if="isTechnician")
              .value-box
                .label {{$t('events.contactProduction')}}
                .value(v-if="localEvent.contactProduction" v-html="markedFkt(localEvent.contactProduction)" )
                .value(v-else) &lt;nicht angegeben&gt;
          v-row
            v-col(:md="6"  cols="12")
              .value-box
                .label {{$t('events.hotelInfo')}}
                .value(v-if="localEvent.hotelInfo" v-html="markedFkt(localEvent.hotelInfo)" )
                .value(v-else) &lt;nicht angegeben&gt;

          v-row( v-if="isTechnician")
            v-col(:md="4" :sm="6" cols="12")
              .value-box
                .label {{$t('events.rollUp')}}
                .value(v-if="hasValue(localEvent.rollUp)")
                  v-btn(block :color="localEvent.rollUp === true?'green':'red'").text-none {{localEvent.rollUp===true?'Ja':'Nein'}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4" :sm="6" cols="12")
              .value-box
                .label {{$t('events.balloon')}}
                .value(v-if="hasValue(localEvent.balloon )")
                  v-btn(block :color="localEvent.balloon === true?'green':'red'").text-none {{localEvent.balloon===true?'Ja':'Nein'}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4" :sm="6" cols="12")
              .value-box
                .label {{$t('events.shooter')}}
                .value(v-if="hasValue(localEvent.shooter )")
                  v-btn(block :color="localEvent.shooter === true?'green':'red'").text-none {{localEvent.shooter===true?'Ja':'Nein'}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4" :sm="6" cols="12")
              .value-box
                .label {{$t('events.peepholeStand')}}
                .value(v-if="hasValue(localEvent.peepholeStand )")
                  v-btn(block :color="localEvent.peepholeStand === true?'green':'red'").text-none {{localEvent.peepholeStand===true?'Ja':'Nein'}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4" :sm="6" cols="12")
              .value-box
                .label {{$t('events.lightSetUp')}}
                .value(v-if="hasValue(localEvent.lightSetUp)")
                  v-btn(block :color="localEvent.lightSetUp === true?'green':'red'").text-none {{localEvent.lightSetUp===true?'Ja':'Nein'}}
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="4" :sm="6"  cols="12")
              .value-box
                .label {{$t('events.completeProduction')}}
                .value(v-if="hasValue(localEvent.completeProduction)")
                  v-btn(block :color="localEvent.completeProduction === true?'green':'red'").text-none {{localEvent.completeProduction===true?'Ja':'Nein'}}
                .value(v-else) &lt;nicht angegeben&gt;
          v-row
            //v-col(:md="12"  cols="12")
            //  .value-box
            //    .label {{$t('events.description')}}
            //    .value(v-if="localEvent.description" v-html="markedFkt(localEvent.description)" )
            //    .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="12"  cols="12" v-if="isPerformer")
              .value-box
                .label {{$t('events.descriptionPerformer')}}
                .value(v-if="localEvent.descriptionPerformer" v-html="markedFkt(localEvent.descriptionPerformer)" )
                .value(v-else) &lt;nicht angegeben&gt;
            v-col(:md="12"  cols="12" v-if="isTechnician")
              .value-box
                .label {{$t('events.descriptionProduction')}}
                .value(v-if="localEvent.descriptionProduction" v-html="markedFkt(localEvent.descriptionProduction)" )
                .value(v-else) &lt;nicht angegeben&gt;
          v-btn(block @click="setCollapsed(true)").text-none.mt-3  {{$t('core.showLess')}}

</template>

<script>
import { mapGetters } from 'vuex'
import { currentUserIsAdmin, isPerformer, isTechnician } from '@/modules/Authentication/helper/Checks'

export default {
  props: ['eventId'],
  name: 'app-events-base-information',
  data () {
    return {
      collapsed: true
    }
  },
  created () {
  },
  methods: {
    setCollapsed (value) {
      this.$set(this, 'collapsed', value)
    },
    markedFkt (value) {
      if (!value) return ''
      return this.marked(value)
    },
    hasValue (value) {
      return !(typeof value === 'undefined' || value === null)
    }

  },
  filters: {
  },
  computed: {
    ...mapGetters({
      getEventByIri: 'events/byId',
      impersonatedUser: 'authentication/impersonatedUser'
    }),
    isTechnician () {
      return isTechnician() || currentUserIsAdmin()
    },
    isPerformer () {
      return isPerformer() || currentUserIsAdmin()
    },
    eventSheetUrl () {
      return `${process.env.VUE_APP_API_BASE_URL}/events/${this.localEvent.id}/sheet${this.impersonatedUser ? '?X-Switch-User=' + this.impersonatedUser.email : ''}`
    },
    localEvent () {
      return this.getEventByIri(this.eventId)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="less">
.value-box{
  padding: 20px 0.5rem 0.5rem;
  border-radius: 5px;
  border: thin solid lightgray;
  position: relative;
  .label{
    color: black;
    font-size: smaller;
    position: absolute;
    font-weight: bold;
    top: 0;
    left: 0.5rem;
  }
  .value{

  }
}
</style>
