<template lang="pug">
  .assignment-state-view.my-1.d-block
    //|{{assignment}}
    v-item-group(v-model="assignment.confirmed" dense v-if="assignment" ).d-inline
      v-item(value='confirmed' ).mx-2
        v-icon(:color="assignment.confirmed === 'confirmed'?'success':'grey lighten-2'"  ) fa-check
      v-item(value='declined' ).mx-2
        v-icon(:color="assignment.confirmed === 'declined'?'error':'grey lighten-2'") fa-times
    v-chip(outlined label disabled).ml-3 {{assignment.type}}

</template>

<script>
import { mapGetters } from 'vuex'
import { extractIri } from '@/modules/Core/helpers/HydraHelper'

export default {
  props: ['assignment'],
  name: 'app-assignment-state-view',
  data () {
    return { localEvent: null }
  },
  created () {
    this.updateLocalEvent()
  },
  methods: {
    updateLocalEvent () {
      this.$set(this, 'localEvent', this.getEventByIri(extractIri(this.eventId)))
    }
  },
  computed: {
    ...mapGetters({ getEventByIri: 'events/byIri' }),
    preview () {
      // console.log(this.localEvent)
      if (!this.localEvent) return ''
      return this.marked(this.localEvent.description)
    }
  },
  watch: {
    eventId () {
      // console.log(this.eventId)
      this.updateLocalEvent()
    }
  }
}
</script>

<style scoped>

</style>
