import { render, staticRenderFns } from "./app-documents-create-button.vue?vue&type=template&id=07a8711e&scoped=true&lang=pug&"
import script from "./app-documents-create-button.vue?vue&type=script&lang=js&"
export * from "./app-documents-create-button.vue?vue&type=script&lang=js&"
import style0 from "./app-documents-create-button.vue?vue&type=style&index=0&id=07a8711e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07a8711e",
  null
  
)

export default component.exports