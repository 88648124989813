<template lang="pug">
  .inline-event-view
    v-row
      v-col(:lg="6" cols="12").py-3.px-2
        app-events-base-information(:eventId="eventId").my-2
        app-assignments-list(:eventId="eventId+''" short).my-2
      v-col(:lg="6"  cols="12")
        app-personal-assignments(:eventId="eventId")
        app-events-documents-list(:eventId="eventId").my-2

</template>

<script>
import { mapActions } from 'vuex'
import AppEventsBaseInformation from '@/modules/Events/components/app-events-base-information.vue'
import AppAssignmentsList from '@/modules/Assigments/components/app-assignments-list.vue'
import AppEventsDocumentsList from '@/modules/Documents/components/app-events-documents-list.vue'
import AppPersonalAssignments from '@/modules/Assigments/components/app-personal-assignments.vue'

export default {
  components: { AppPersonalAssignments, AppEventsDocumentsList, AppAssignmentsList, AppEventsBaseInformation },
  props: ['eventId'],
  name: 'app-event-inline-view',
  data () {
    return { localEvent: null }
  },
  created () {
    this.loadUsers()
    this.loadEvent(this.eventId)
    this.loadAssignments({ event: this.eventId })
    this.loadDocuments({ event: this.eventId })
  },
  methods: {
    ...mapActions({
      loadEvent: 'events/getEntry',
      loadUsers: 'users/getEntries',
      loadAssignments: 'assignments/getEntries',
      loadDocuments: 'documents/getEntries'
    })
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>

</style>
